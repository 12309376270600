import classNames from "classnames";
import get from "lodash/get";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { setup as reactContentfulImageSetup } from "react-contentful-image";

import { baseEnvironmentConfig } from "@/api/constants";
import { ACDL_JOIN_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/join-flow";
import {
  ACDL_LEAD_FLOW_TRACKING,
  LEAD_FLOW_CATEGORY_TYPES,
} from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { CONTENT_TYPES } from "@/constants";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { UserContext } from "@/context/User";
import useAcdlCustomEvent from "@/hooks/useAcdlCustomEvent";
import useWebView from "@/hooks/useWebView";

import styles from "./Layout.module.scss";
import {
  CUSTOM_FORM_IDENTIFIER,
  DIRECT_RESPONSE_HERO_IDENTIFIER,
  EMBEDDED_JOIN_FLOW_IDENTIFIER,
  LEAD_FLOW_IDENTIFIER,
  media,
  variants,
} from "./constants";

const GlobalFooter = dynamic(() => import("@/components/GlobalFooter"));
const GlobalHeader = dynamic(() => import("@/components/GlobalHeader"));
const NotificationBanner = dynamic(
  () => import("@/components/NotificationBanner")
);
const Chat = dynamic(() => import("@/components/chat"));
const SEOandAnalytics = dynamic(() => import("@/components/seo-and-analytics"));

const Layout = ({
  chatEnabled,
  children,
  club,
  facilityData,
  hideFooter,
  layoutBlock = "start",
  mainClassName,
  metaData,
  modules,
  moduleSpacing,
  navigation,
  notificationBanner,
  page,
  pageSlug,
  theme,
  useSimpleHeader,
}) => {
  const context = useContext(UserContext);
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);
  const isWebView = useWebView();

  const { session } = context;

  useEffect(() => {
    reactContentfulImageSetup(media, variants);
  }, []);

  const checkIsEngageOrNonEngage = () => {
    const moduleForm = modules?.filter((module) => {
      const {
        contentType: {
          sys: { id },
        },
      } = module.sys;
      return id === CONTENT_TYPES.MODULE_FORM;
    });

    const formType = get(moduleForm, "[0].fields.formType", "lead");

    const leadCategory =
      formType === "lead"
        ? LEAD_FLOW_CATEGORY_TYPES.ENGAGE
        : LEAD_FLOW_CATEGORY_TYPES.NON_ENGAGE;
    return leadCategory;
  };

  // scroll event for ACDL when the  lead flow is in viewport
  useAcdlCustomEvent(
    setNewDataLayer,
    modules,
    CONTENT_TYPES.MODULE_LEAD_FLOW,
    ACDL_LEAD_FLOW_TRACKING.leadScrollEvent(),
    LEAD_FLOW_IDENTIFIER
  );

  // Direct Response Hero Lead
  useAcdlCustomEvent(
    setNewDataLayer,
    modules,
    CONTENT_TYPES.MODULE_LEAD_FLOW,
    ACDL_LEAD_FLOW_TRACKING.leadScrollEvent(),
    DIRECT_RESPONSE_HERO_IDENTIFIER,
    "load"
  );

  // scroll event for ACDL when the  join flow is in viewport
  useAcdlCustomEvent(
    setNewDataLayer,
    modules,
    CONTENT_TYPES.MODULE_MEMBERSHIP_OPTION,
    ACDL_JOIN_FLOW_TRACKING.joinScrollEvent(
      page === baseEnvironmentConfig.pageNames.clubDetail
    ),
    EMBEDDED_JOIN_FLOW_IDENTIFIER
  );

  // scroll event for ACDL when the custom form is in viewport
  const leadCategory = checkIsEngageOrNonEngage();
  useAcdlCustomEvent(
    setNewDataLayer,
    modules,
    CONTENT_TYPES.MODULE_FORM,
    ACDL_LEAD_FLOW_TRACKING.leadScrollEvent(leadCategory),
    CUSTOM_FORM_IDENTIFIER
  );

  return (
    <>
      <SEOandAnalytics
        clubData={club}
        facilityData={facilityData}
        metaData={metaData}
      />

      {navigation && !isWebView && (
        <GlobalHeader useSimpleHeader={useSimpleHeader} />
      )}

      {notificationBanner && !session.user && (
        <NotificationBanner
          club={club}
          data={notificationBanner}
          stickyOffSet={page}
        />
      )}

      {chatEnabled &&
        process.env.NEXT_PUBLIC_DISABLE_FEATURE_SALESFORCE_CHAT !== "true" && (
          <Chat pageName={page} session={session} />
        )}

      <main
        className={classNames(styles.main, mainClassName)}
        data-layout-block={layoutBlock}
        data-module-spacing={moduleSpacing}
        data-page={pageSlug}
        data-theme={theme}
      >
        {children}
      </main>

      {!hideFooter && !isWebView && <GlobalFooter />}
    </>
  );
};

Layout.propTypes = {
  chatEnabled: PropTypes.bool,
  children: PropTypes.node,
  club: PropTypes.object,
  facilityData: PropTypes.object,
  hideFooter: PropTypes.bool,
  layoutBlock: PropTypes.string,
  mainClassName: PropTypes.string,
  metaData: PropTypes.shape({
    allowSearchEngineIndex: PropTypes.bool,
    description: PropTypes.string,
    keywords: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  moduleSpacing: PropTypes.string,
  modules: PropTypes.array,
  navigation: PropTypes.object,
  notificationBanner: PropTypes.object,
  page: PropTypes.string,
  pageSlug: PropTypes.string,
  theme: PropTypes.string,
  useSimpleHeader: PropTypes.bool,
};

Layout.defaultProps = {
  chatEnabled: true,
  hideFooter: false,
  metaData: {},
  modules: [],
  navigation: undefined,
  page: "",
  theme: "dark",
  useSimpleHeader: false,
};

export default Layout;
