const COUNTRY_CODE_CURRENCY = {
  US: "usd",
  CA: "cad",
  UK: "gbp",
};

export const getCurrencyCode = (country) => COUNTRY_CODE_CURRENCY[country];

export const PAGE_CATEGORY_ENUM = {
  ACCOUNT: "my-account",
  JOIN: "join-interest",
  DISCOVERY: "discovery",
  HIGH_INTEREST: "high interest",
  LEAD_GEN: "lead-gen",
  BOOKING: "booking",
  NON_ACQ: "non-acquisition",
  DAILY_CHECKIN: "daily checkin",
  MISC: "misc",
  PRE_SALE: "pre-sale",
  ERROR: "error",
};

const CLUB_STATUS = {
  Presale: "presale",
  ComingSoon: "coming soon",
  LeadInept: "lead inept",
  Open: "open",
  Closed: "closed",
};

export const getClubStatus = (facilityStatus) => CLUB_STATUS[facilityStatus];

export const processUrl = (url = "") =>
  url.split("#")[0].split("?")[0].split("/");
