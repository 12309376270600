const screenXs = "0px";
const screenSm = "486px";
const screenMd = "768px";
const screenLg = "1192px";
const screenXl = "1448px";

export const media = {
  xs: `(max-width: ${screenXs})`,
  sm: `(max-width: ${screenSm})`,
  md: `(max-width: ${screenMd})`,
  "md-up": `(min-width: ${screenMd})`,
  lg: `(max-width: ${screenLg})`,
  xl: `(max-width: ${screenXl})`,
  dpr2: "(min-resolution: 144dpi)", // 1.5x devices and up get 2x images
  dpr3: "(min-resolution: 240dpi)", // 2.5x devices and up get 3x images
  portrait: "(orientation: portrait)",
  landscape: "(orientation: landscape)",
};

export const LEAD_FLOW_IDENTIFIER = `data-leadview="lead-view"`;
export const EMBEDDED_JOIN_FLOW_IDENTIFIER = `data-joinview="join-view"`;
export const CUSTOM_FORM_IDENTIFIER = `data-leadview="moduleForm"`;
export const DIRECT_RESPONSE_HERO_IDENTIFIER = `data-leadview="lead-view-direct-response"`;

// Object keys are media query names from above. 'default' means no media query (default match).
// E.g. for mobile first 'default' would be the mobile media query
export const variants = {
  default: {
    quality: 45,
    density: 1,
  },
  dpr2: {
    quality: 35,
    density: 2,
  },
  dpr3: {
    quality: 35,
    density: 2,
  },
};
