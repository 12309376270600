import {
  PAGE_CATEGORY_ENUM,
  getClubStatus,
  processUrl,
} from "../acdl-data-layer";
import { PAGE_TYPE_ENUM, assemblePageObject } from "./global-tracking";
import { LEAD_FLOW_TYPES } from "./lead-flow";

const JOIN_FLOW_URL = "/join/step2/";

export const JOIN_FLOW_MEMBERSHIP_TYPES = {
  SINGLE: "single club",
  MULTIPLE: "multiple clubs",
};

const JOIN_FLOW_STEP_NAMES = {
  CLUB_SELECTED: "club selected",
  SELECT_A_CLUB: "join select a club",
  MEMBERSHIP_VIEW: "membership view",
  MEMBERSHIP_SELECTED: "membership selected",
  LEAD_VIEW: "join lead view",
  LEAD_SUBMIT: "join lead submit",
  LEAD_ERROR: "join lead error",
  CONFIRMATION: "join confirmation",
};

const JOIN_FLOW_CATEGORY_TYPES = {
  ENGAGE: "join flow | engage",
  NON_ENGAGE: "join flow | non-engage",
};

export const ACDL_JOIN_FLOW_TRACKING = {
  joinFlowSelectAClub: (url) => {
    const urlSections = processUrl(url);
    return {
      event: "page-load",
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.JOIN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
      user: {
        loginStatus: "not-authenticated",
      },
      joinFlow: {
        step: JOIN_FLOW_STEP_NAMES.SELECT_A_CLUB,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
      },
    };
  },
  joinFlowClubSelected: (
    type,
    facilityID,
    clubID,
    clubRegion,
    clubName,
    clubStatus,
    clubType
  ) => ({
    event: "join-flow",
    joinFlow: {
      step: JOIN_FLOW_STEP_NAMES.CLUB_SELECTED,
      type,
      facilityID,
      clubID: clubID.toString(),
      clubRegion,
      clubName,
      clubStatus: getClubStatus(clubStatus),
      clubType: clubType && clubType.toLowerCase(),
    },
  }),
  joinFlowMembershipSelected: (
    membership,
    potentialRevenue,
    currencyCode,
    facilityId = null
  ) => ({
    event: "join-flow",
    joinFlow: {
      step: JOIN_FLOW_STEP_NAMES.MEMBERSHIP_SELECTED,
      membership,
      potentialRevenue,
      currencyCode,
      ...(facilityId && { facilityID: facilityId }),
    },
  }),
  joinFlowLeadView: (facId) => {
    const url = JOIN_FLOW_URL + facId;
    const urlSections = processUrl(url);
    return {
      event: "page-load",
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.JOIN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
      joinFlow: {
        step: JOIN_FLOW_STEP_NAMES.LEAD_VIEW,
        category: JOIN_FLOW_CATEGORY_TYPES.ENGAGE,
      },
    };
  },
  joinFlowMembershipView: (
    url,
    isEmbedded = false,
    isLocationAutoDetected = false
  ) => {
    const urlSections = processUrl(url);

    if (isEmbedded) {
      return {
        event: "join-flow",
        joinFlow: {
          step: JOIN_FLOW_STEP_NAMES.MEMBERSHIP_VIEW,
          type: LEAD_FLOW_TYPES.EMBEDDED,
          autoDetection: isLocationAutoDetected ? "Y" : "N",
        },
      };
    }

    return {
      event: "page-load",
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.JOIN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
      joinFlow: {
        step: JOIN_FLOW_STEP_NAMES.MEMBERSHIP_VIEW,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
        autoDetection: isLocationAutoDetected ? "Y" : "N",
      },
    };
  },
  joinFlowLeadSubmit: (
    hashedEmail,
    leadTransactionID,
    activationCode,
    formattedSource
  ) => ({
    event: "join-flow",
    joinFlow: {
      step: JOIN_FLOW_STEP_NAMES.LEAD_SUBMIT,
      type: LEAD_FLOW_TYPES.NON_EMBEDDED,
      category: JOIN_FLOW_CATEGORY_TYPES.ENGAGE,
      hashedEmail,
      leadTransactionID,
      ...(activationCode && { activationCode: activationCode.toLowerCase() }),
      ...(formattedSource && { source: formattedSource }),
    },
  }),
  joinFlowLeadError: (error) => ({
    event: "join-flow",
    joinFlow: {
      step: JOIN_FLOW_STEP_NAMES.LEAD_ERROR,
      category: JOIN_FLOW_CATEGORY_TYPES.ENGAGE,
      error,
    },
  }),
  joinScrollEvent: (isClubDetailPage = false) => ({
    event: "join-flow",
    joinFlow: {
      step: isClubDetailPage
        ? JOIN_FLOW_STEP_NAMES.MEMBERSHIP_VIEW
        : JOIN_FLOW_STEP_NAMES.SELECT_A_CLUB,
      type: LEAD_FLOW_TYPES.EMBEDDED,
    },
  }),
  joinFlowConfirmation: (url, barcode) => {
    const urlSections = processUrl(url);
    return {
      event: "page-load",
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.JOIN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
      joinFlow: {
        step: JOIN_FLOW_STEP_NAMES.CONFIRMATION,
        barcode,
      },
    };
  },
  joinCtaEvent: (ctaName, sourceName) => ({
    event: "join-flow",
    joinFlow: {
      step: "join cta",
      source: `${ctaName.toLowerCase()} | ${sourceName}`,
    },
  }),
  joinFlowMembershipPageView: (
    url,
    facilityID,
    clubID,
    clubRegion,
    clubName,
    clubStatus,
    clubType
  ) => {
    const urlSections = processUrl(url);
    return {
      event: "page-load",
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.JOIN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
      user: {
        loginStatus: "not-authenticated",
      },
      joinFlow: {
        step: JOIN_FLOW_STEP_NAMES.MEMBERSHIP_VIEW,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
        facilityID,
        clubID: clubID.toString(),
        clubRegion,
        clubName,
        clubStatus: getClubStatus(clubStatus),
        clubType: clubType && clubType.toLowerCase(),
      },
    };
  },
};
