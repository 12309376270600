import { format as formatDate, isToday, isTomorrow } from "date-fns";

import { DATETIME } from "@/constants";

import {
  PAGE_CATEGORY_ENUM,
  getClubStatus,
  processUrl,
} from "../acdl-data-layer";
import { PAGE_TYPE_ENUM, assemblePageObject } from "./global-tracking";

export const LEAD_FLOW_CTA_SOURCE = {
  HERO: "hero banner",
  NAV: "global nav",
  PROMO: "promo banner",
  BODY: "body",
  FOOTER: "footer",
};

export const LEAD_FLOW_STEP_NAMES = {
  ERROR: "lead error",
  SUBMIT: "lead submit",
  CLUB_SELECTION: "club selection",
  LEAD_VIEW: "lead view",
  CTA: "lead cta",
  SURVEY_INTEREST: "survey-interest",
  SURVEY_EXPERIENCE: "survey-experience",
  SURVEY_GOALS: "survey-goals",
  SURVEY_INFLUENCE: "survey-influence",
  SCHEDULE_A_VISIT_VIEW: "lead schedule visit view",
  SCHEDULE_A_VISIT_CONFIRMATION: "lead schedule visit selected",
  SCHEDULE_A_WORKOUT_VIEW: "lead schedule workout view",
  SCHEDULE_A_WORKOUTT_CONFIRMATION: "lead schedule workout selected",
};

export const LEAD_FLOW_TYPES = {
  EMBEDDED: "embedded",
  NON_EMBEDDED: "non-embedded",
};

export const LEAD_FLOW_CATEGORY_TYPES = {
  ENGAGE: "lead flow | engage",
  NON_ENGAGE: "lead flow | non-engage",
};

export const formatTime = (selectedDate, selectedTime) => {
  const theDate = new Date(selectedDate.split("T")[0] + "T" + selectedTime);

  let theString = "";

  if (isToday(theDate)) {
    theString = "Today";
  } else if (isTomorrow(theDate)) {
    theString = "Tomorrow";
  } else {
    theString = DATETIME.longWeekdayNameFormatter.format(theDate);
  }

  return `${theString}, ${formatDate(theDate, "MMMM dd 'at' h:mm a")}`;
};

export const assembleLeadFlowObject = (
  stepName,
  type,
  category,
  clubName,
  clubRegion,
  clubID,
  facilityID,
  hashedEmail,
  transactionID,
  error,
  clubStatus,
  clubType,
  activationCode
) => {
  const lead = {
    step: stepName,
    type,
    category,
    clubName: clubName.toLowerCase(),
  };

  if (clubID) {
    lead.clubID = clubID.toString();
  }

  if (clubRegion) {
    lead.clubRegion = clubRegion.toLowerCase();
  }

  if (facilityID) {
    lead.facilityID = facilityID.toString();
  }

  if (hashedEmail) {
    lead.hashedEmail = hashedEmail;
  }

  if (transactionID) {
    lead.transactionID = transactionID.toString();
  }

  if (error) {
    lead.error = error;
  }

  if (clubStatus) {
    lead.clubStatus = getClubStatus(clubStatus);
  }

  if (clubType) {
    lead.clubType = clubType.toLowerCase();
  }

  if (activationCode) {
    lead.activationCode = activationCode.toLowerCase();
  }

  return lead;
};

const formClubInformation = (clubInformation) => {
  if (clubInformation) {
    const { name, facilityId, region, status, clubId, clubType } =
      clubInformation;

    if (name && facilityId && region && status && clubId && clubType) {
      return {
        clubName: name.toLowerCase(),
        facilityID: String(facilityId),
        clubRegion: region.toLowerCase(),
        clubStatus: getClubStatus(status),
        clubID: String(clubId),
        clubType: clubType.toLowerCase(),
      };
    }
  }

  console.warn("formClubInformation received incomplete clubInformation.");

  return {};
};

export const ACDL_LEAD_FLOW_TRACKING = {
  leadFlowCTA: (source, clubInformation = null) => ({
    event: "lead-flow",
    leadFlow: {
      step: LEAD_FLOW_STEP_NAMES.CTA,
      source: source && source.toLowerCase(),
      ...formClubInformation(clubInformation),
    },
  }),
  leadFlowClubSelection: (
    clubName,
    facilityID,
    clubRegion,
    clubStatus,
    clubID,
    clubType
  ) => ({
    event: "lead-flow",
    leadFlow: {
      step: LEAD_FLOW_STEP_NAMES.CLUB_SELECTION,
      clubName,
      facilityID,
      clubRegion,
      clubStatus: getClubStatus(clubStatus),
      clubID: clubID.toString(),
      clubType: clubType.toLowerCase(),
    },
  }),
  leadFlowSubmitLead: (
    stepName,
    type,
    category,
    clubName,
    facilityID,
    hashedEmail,
    transactionID,
    clubRegion,
    clubStatus,
    clubType,
    clubID,
    activationCode
  ) => ({
    event: "lead-flow",
    leadFlow: assembleLeadFlowObject(
      stepName,
      type,
      category,
      clubName,
      clubRegion,
      clubID,
      facilityID,
      hashedEmail,
      transactionID,
      undefined,
      clubStatus,
      clubType,
      activationCode
    ),
  }),
  leadFlowErrorLead: (
    stepName,
    type,
    category,
    clubName,
    clubRegion,
    facilityID,
    error,
    clubStatus,
    clubType,
    clubID
  ) => ({
    event: "lead-flow",
    leadFlow: assembleLeadFlowObject(
      stepName,
      type,
      category,
      clubName,
      clubRegion,
      clubID,
      facilityID,
      undefined,
      undefined,
      error,
      clubStatus,
      clubType
    ),
  }),
  leadFlowSurvey: (step, answers) => ({
    event: "lead-survey",
    leadFlow: {
      step,
      surveyQA: answers,
    },
  }),
  leadView: (url, clubInformation = null) => {
    const urlSections = processUrl(url);
    return {
      event: "page-load",
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.LEAD_GEN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
      user: {
        loginStatus: "not-authenticated",
      },
      leadFlow: {
        step: LEAD_FLOW_STEP_NAMES.LEAD_VIEW,
        category: LEAD_FLOW_CATEGORY_TYPES.ENGAGE,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
        ...formClubInformation(clubInformation),
      },
    };
  },

  scheduleAVisit: (url, clubInformation = null) => {
    const urlSections = processUrl(url);
    return {
      event: "page-load",
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.LEAD_GEN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
      user: {
        loginStatus: "not-authenticated",
      },
      leadFlow: {
        step: LEAD_FLOW_STEP_NAMES.SCHEDULE_A_VISIT_VIEW,
        category: LEAD_FLOW_CATEGORY_TYPES.ENGAGE,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
        ...formClubInformation(clubInformation),
      },
    };
  },

  confirmAVisit: (selectedDate, selectedTime, isSkip) => {
    return {
      event: "lead-visit",
      leadFlow: {
        step: LEAD_FLOW_STEP_NAMES.SCHEDULE_A_VISIT_CONFIRMATION,
        ...(selectedDate && {
          visitTime: isSkip
            ? selectedDate.toLowerCase()
            : formatTime(selectedDate, selectedTime),
        }),
      },
    };
  },

  confirmAWorkout: (selectedDate, selectedTime, isSkip) => {
    return {
      event: "lead-workout",
      leadFlow: {
        step: LEAD_FLOW_STEP_NAMES.SCHEDULE_A_WORKOUT_CONFIRMATION,
        ...(selectedDate && {
          workoutTime: isSkip
            ? selectedDate.toLowerCase()
            : formatTime(selectedDate, selectedTime),
        }),
      },
    };
  },

  surveyView: (url, step) => {
    const urlSections = processUrl(url);
    return {
      event: "page-load",
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.LEAD_GEN,
        PAGE_TYPE_ENUM.PROGRAMATIC,
        ` | ${step}`
      ),
      user: {
        loginStatus: "not-authenticated",
      },
      leadFlow: {
        step: `${step} view`,
        category: LEAD_FLOW_CATEGORY_TYPES.ENGAGE,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
      },
    };
  },
  leadScrollEvent: (category = LEAD_FLOW_CATEGORY_TYPES.ENGAGE) => ({
    event: "lead-flow",
    leadFlow: {
      step: "lead view",
      type: LEAD_FLOW_TYPES.EMBEDDED,
      category,
    },
  }),
};
