import { PAGE_CATEGORY_ENUM, processUrl } from "../acdl-data-layer";
import {
  LEAD_FLOW_CATEGORY_TYPES,
  LEAD_FLOW_STEP_NAMES,
  LEAD_FLOW_TYPES,
} from "./lead-flow";

export const PAGE_TYPE_ENUM = {
  PROGRAMATIC: "programmatic",
  NON_PROGRAMATIC: "non-programmatic",
};

export const assemblePageObject = (
  url,
  title,
  category,
  type,
  concatToName = ""
) => {
  const page = {
    name: `eqx${url.join(":")}`,
    section: url[1],
    subSection: url[1],
  };
  if (type) {
    page.type = type;
  }
  if (category) {
    page.category = category;
  }
  if (title) {
    page.title = title;
  }
  if (url[2]) {
    page.subSection = url[2];
  } else {
    page.name = page.name + ":" + url[1];
  }

  if (concatToName) {
    page.name = page.name + concatToName;
  }

  return page;
};

export const HOME_PAGE_PAGE_OBJECT = (isLeadView = false) => ({
  name: isLeadView ? `eqx:home:home | lead form` : `eqx:home:home`,
  section: `home`,
  subSection: `home`,
  type: PAGE_TYPE_ENUM.PROGRAMATIC,
  category: PAGE_CATEGORY_ENUM.DISCOVERY,
});

export const ACDL_GLOBAL_TRACKING = {
  programmaticUrlRoute: (url, title, category, concatToName) => {
    const urlSections = processUrl(url);
    return {
      event: "page-load",
      page: assemblePageObject(
        urlSections,
        title,
        category,
        PAGE_TYPE_ENUM.PROGRAMATIC,
        concatToName
      ),
    };
  },
  nonProgrammaticUrlRoute: (url, title, category) => {
    const urlSections = processUrl(url);
    return {
      event: "page-load",
      page: assemblePageObject(
        urlSections,
        title,
        category,
        PAGE_TYPE_ENUM.NON_PROGRAMATIC
      ),
    };
  },
  homePage: (title) => ({
    event: "page-load",
    page: {
      ...HOME_PAGE_PAGE_OBJECT(),
      title,
    },
  }),
  popeLead: (url, title, category) => {
    const urlSections = processUrl(url);
    return {
      event: "page-load",
      page: assemblePageObject(urlSections, title, category),
      leadFlow: {
        step: LEAD_FLOW_STEP_NAMES.LEAD_VIEW,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
        category: LEAD_FLOW_CATEGORY_TYPES.ENGAGE,
      },
    };
  },
  classScheduleDownloadUrl: (url) => {
    const urlSections = processUrl(url);
    return {
      event: "class-schedule-download",
      page: assemblePageObject(urlSections),
    };
  },
  singleFlowJoinNow: (url) => {
    const urlSections = processUrl(url);
    return {
      event: "single-flow-join-now",
      page: assemblePageObject(urlSections),
    };
  },
  singleFlowLearnMore: (url) => {
    const urlSections = processUrl(url);
    return {
      event: "single-flow-learn-more",
      page: assemblePageObject(urlSections),
    };
  },
};
